<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5">
        <section class="grid grid-cols-1 md:grid-cols-1 gap-6">
          <draggable
                class="draggable1"
                group="list"
                v-model="data"
                tag="ul"
                :component-data="getComponentData(null)"
                :empty-insert-threshold="emptyInsertThreshold"
                :fallbackOnBody="fallbackOnBody"
                :swapThreshold="swapThreshold"
                :animation="animation"
                :move="onMove"
                :dragClass="dragClass"
                :chosenClass="chosenClass"
                :ghostClass="ghostClass"
                handle=".handle"
                @change="onChange"
                @update="onUpdate"
                @start="onStart"
                @end="onEnd"
                @choose="onChoose"
                @sort="onSort"
              >
                <!-- 第一層 -->
                <li class="item_wrap" v-for="item in data" :key="item.id">
                  <div class="item">
                    <div class="flex items-center w-0 flex-auto">
                      <svg-icon class="handle flex-shrink-0" icon-class="drag" className="gray-divide"></svg-icon>
                      <p class="text-limit-1">{{ item.name }}</p>
                    </div>
                  </div>
                </li>
            </draggable>
            <div class="flex justify-between">
              <div class="w-4"></div>
              <vs-button size="large" ref="saveBtn" @click="saveBtn()">
                <span class="font-medium">儲存</span>
              </vs-button>
            </div>
        </section>
      </main>
    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import drawer from '@/components/drawer.vue'

export default {
  name: 'rdevices_rank',
  components: {
    draggable,
    drawer,
  },
  data() {
    return {
      type: 2,
      fname: 'rdevices',
      delete_dialog: false,
      data: [
      ],
      animation: 150,
      swapThreshold: 0.3,
      fallbackOnBody: false,
      emptyInsertThreshold: 10,
      dragClass: 'dragClass', // 拖動時的懸浮樣式
      chosenClass: 'chosenClass', // 被選中的區塊樣式
      ghostClass: 'ghostClass',
    }
  },
  created() {
    this.init()
  },

  methods: {
    init() {
      this.loadData()
    },
    loadData() {
      this.$axios({
        url: `backstage/rooms/devices`,
        method: 'get',
        params: {
          type: this.type,
          page: 1,
          per_page: 5000,
        },
      }).then((res) => {
        // console.log(res)
        if (res.data.status) {
          this.is_API_loaded = true
          this.data = res.data.data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    getComponentData(index) {
      return {
        props: {
          list: this.data[index],
        },
      }
    },
    onMove(evt, originalEvent) {
    },
    // 當元素被移動時觸發
    onChange(evt) {
    },
    onChoose(evt) {
      // console.log(evt)
    },
    onUpdate(evt) {
      // console.log(evt)
    },
    onStart(evt) {
      // console.log(evt)
    },
    onSort(evt) {
      // console.log(evt)
    },
    onEnd(evt) {},


    saveBtn() {
      this.saveSubmit('backstage/rooms/devices_rank', 'put');
    },
    saveSubmit(url, method) {
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: url,
        method: method,
        data: this.data,
      }).then((res) => {
        loading.close()
        if(res){
           if (res.data.status) {
            this.$myVS.openNoti(res.data.message, '', undefined)
            this.$router.replace({
              name: this.fname,
              query: this.$route.query
            })
          } else {
            this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.box {
  @apply bg-white rounded-xl;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

.draggable1 {
  @apply space-y-2 pb-2;
}

.item {
  @apply h-10 rounded-xl flex items-center justify-between border px-2;
  background-color: white;
}

.item_wrap {
}

.dragClass {
  @apply shadow-lg overflow-hidden border-success bg-white opacity-100 rounded-xl;
}

.chosenClass {
  @apply border border-success rounded-xl overflow-hidden;
}

.handle {
  cursor: grab;
  margin: 0px 8px 0px 0px;
}

</style>
